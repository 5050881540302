import React from 'react';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { navigate } from 'gatsby';
import { AboutUsData } from '../../../data/db';
import './about-us.scss';

export default function AboutUs() {
  return (
    <section className="about-us">
      <Container>
        <Row>
          <Col className="text-area col-12 col-md-5 order-last order-md-first pt-5">
            <h2 className="fs-2 mb-4">{AboutUsData[0].heading}</h2>
            <p className="subheading custom-text-justify fs-6">{AboutUsData[0].text}</p>
            <Button variant="primary" className="infenox-button" role="link" onClick={() => { navigate('/about'); }}>{AboutUsData[0].buttonLabel}</Button>
          </Col>
          <Col className="image-area col-12 col-md-7 text-center text-md-start">
            <img src={AboutUsData[0].image3} className="about-image" alt="Coder" />
          </Col>
        </Row>
      </Container>
    </section>
  );
}
